export const AuthHeader = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
        return {
            Authorization: `Bearer ${user.access_token}`,
            "Content-Type": "application/json",
        };
    } else {
        return { "Content-Type": "application/json" };
    }
};
