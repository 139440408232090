import { Link, Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/services/auth.service";
import { Button } from "react-bootstrap";
import jwtDecode from "jwt-decode";
import SideBarMenu from "./sidebar";

export default function AdminLayout() {
    var location = useLocation();
    const [sidebarActive, setSidebarActive] = useState(true);
    const [showBackdrop, setshowBackdrop] = useState(false);
    const [adminName, setAdminName] = useState("");
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);

    const handleSidebar = () => {
        setSidebarActive(!sidebarActive);
    };

    useEffect(() => {
        setshowBackdrop(window.outerWidth <= 1200 && sidebarActive);
    }, [sidebarActive]);

    useEffect(() => {
        if (window.outerWidth <= 1200) {
            setSidebarActive(false);
        }
    }, [location.pathname]);

    useEffect(() => {
        var token = jwtDecode(user.access_token);
        setAdminName(token.full_name);

        function handleResize() {
            setSidebarActive(window.outerWidth > 1200);
        }

        window.addEventListener("resize", handleResize);

        return (_) => {
            window.removeEventListener("resize", handleResize);
        };

        // eslint-disable-next-line
    }, []);

    const handleLogout = () => {
        //console.log("logout called");
        return dispatch(logout());
    };

    return (
        <>
            <div id="app">
                <div
                    id="sidebar"
                    className={sidebarActive ? "active" : "inactive"}
                >
                    <div className="sidebar-wrapper active ">
                        <div className="sidebar-header position-relative">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="logo">
                                    <Link to="/admin">Post A Job</Link>
                                </div>
                                <div className="sidebar-toggler">
                                    <Link
                                        className="sidebar-hide d-xl-none d-block"
                                        onClick={handleSidebar}
                                    >
                                        <i className="bi bi-x bi-middle"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <SideBarMenu />
                    </div>
                </div>
                <div id="main" className="layout-navbar navbar-fixed">
                    <header>
                        <nav className="navbar navbar-expand navbar-light navbar-top">
                            <div className="container-fluid">
                                <Button
                                    variant="light"
                                    className="burger-btn d-block btn-sm"
                                    onClick={handleSidebar}
                                >
                                    <i className="bi bi-justify fs-3"></i>
                                </Button>

                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div
                                    className="collapse navbar-collapse"
                                    id="navbarSupportedContent"
                                >
                                    <ul className="navbar-nav ms-auto mb-lg-0">
                                        <li className="nav-item dropdown me-3">
                                            <Link style={{display:"none"}}
                                                className="nav-link active dropdown-toggle text-gray-600"
                                                to="/admin"
                                                data-bs-toggle="dropdown"
                                                data-bs-display="static"
                                                aria-expanded="false"
                                            >
                                                <i className="bi bi-bell bi-sub fs-4"></i>
                                                <span className="position-absolute translate-middle badge rounded-pill bg-danger">
                                                    1
                                                </span>
                                            </Link>
                                            <ul
                                                className="dropdown-menu dropdown-menu-end notification-dropdown"
                                                aria-labelledby="dropdownMenuButton"
                                            >
                                                <li className="dropdown-header">
                                                    <h6>Notifications</h6>
                                                </li>
                                                <li className="dropdown-item notification-item">
                                                    <Link className="d-flex align-items-center">
                                                        <div className="notification-icon bg-primary">
                                                            <i className="bi bi-cart-check"></i>
                                                        </div>
                                                        <div className="notification-text ms-4">
                                                            <p className="notification-title font-bold">
                                                                Successfully
                                                                check out
                                                            </p>
                                                            <p className="notification-subtitle font-thin text-sm">
                                                                Order ID #256
                                                            </p>
                                                        </div>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <div className="dropdown">
                                        <a
                                            href="/admin"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <div className="user-menu d-flex">
                                                <div className="user-name text-end me-3">
                                                    <h6 className="mb-0 text-gray-600">
                                                        {adminName}
                                                    </h6>
                                                    <p className="mb-0 text-sm text-gray-600">
                                                        Admin
                                                    </p>
                                                </div>
                                                <div className="user-img align-items-center text-gray-600">
                                                    <i className="bi bi-person bi-sub fs-4"></i>
                                                </div>
                                            </div>
                                        </a>
                                        <ul
                                            className="dropdown-menu dropdown-menu-end"
                                            aria-labelledby="dropdownMenuButton"
                                            style={{ minWidth: "11rem" }}
                                        >
                                            <li>
                                                <h6 className="dropdown-header">
                                                    Hello, {adminName}!
                                                </h6>
                                            </li>
                                            <li>
                                                <Link
                                                    className="dropdown-item"
                                                    to="/profile"
                                                >
                                                    <i className="icon-mid bi bi-person me-2"></i>{" "}
                                                    My Profile
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    className="dropdown-item"
                                                    to="/change-password"
                                                >
                                                    <i className="icon-mid bi bi-key me-2"></i>{" "}
                                                    Change Password
                                                </Link>
                                            </li>
                                            <li>
                                                <hr className="dropdown-divider" />
                                            </li>
                                            <li>
                                                <Link
                                                    className="dropdown-item"
                                                    onClick={handleLogout}
                                                >
                                                    <i className="icon-mid bi bi-box-arrow-left me-2"></i>
                                                    Logout
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </header>
                    <div id="main-content">
                        <Outlet />
                    </div>
                    <footer></footer>
                </div>
            </div>
            <div
                className="sidebar-backdrop"
                style={{ display: showBackdrop ? "block" : "none" }}
                onClick={handleSidebar}
            ></div>
        </>
    );
}
