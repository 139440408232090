export const adminMenu = [
    {
        title: "Dashboard",
        url: "/admin",
        icon: "bi bi-grid-fill",
    },
    {
        title: "Admin Users",
        url: "/admin/user/admin",
        icon: "bi bi-person-fill",
    },
    {
        title: "Client Users",
        url: "/admin/user/client",
        icon: "bi bi-people",
    },
    {
        title: "Logs",
        url: "#",
        icon: "bi bi-clock-history",
        submenu: [
            {
                title: "Seri Logs",
                url: "/admin/serilog/list",
            },
            {
                title: "Email Logs",
                url: "/admin/emaillog/list",
            },
            // {
            //     title: "Email Logs",
            //     url: "/emaillog",
            // },
        ],
    },
    {
        title: "Jobs",
        url: "/admin/jobs",
        icon: "bi bi-briefcase",
    },
    {
        title: "Lead Generation",
        url: "/admin/lead-generation",
        icon: "bi bi-bi bi-people-fill",
    },
];
