import _axios from "./api";
import { LOGIN_SUCCESS, LOGOUT } from "./actions/types";
import * as ServiceAPI from "./services/auth.service";

const Interceptor = (store) => {
    const shouldIntercept = (error) => {
        try {
            return error.response.status === 401;
        } catch (e) {
            return false;
        }
    };

    const setTokenData = (tokenData = {}, axiosClient) => {
        localStorage.setItem("user", JSON.stringify(tokenData));
    };

    const handleTokenRefresh = async () => {
        var storeState = store.getState();
        const currentAccesstoken = storeState.auth.user.access_token;
        const currentRereshtoken = storeState.auth.user.refresh_token;
        if (!currentAccesstoken || !currentRereshtoken) {
            return;
        }

        return new Promise((resolve, reject) => {
            _axios
                .post(`/refresh-token`, {
                    accessToken: currentAccesstoken,
                    refreshToken: currentRereshtoken,
                })
                .then((response) => {
                    const data = response.data;
                    store.dispatch({
                        type: LOGIN_SUCCESS,
                        payload: { user: data },
                    });

                    resolve(data);
                })
                .catch((err) => {
                    ServiceAPI.logout();
                    store.dispatch({
                        type: LOGOUT,
                    });
                    reject(err);
                });
        });
    };

    const attachTokenToRequest = (request, token) => {
        request.headers["Authorization"] = "Bearer " + token;
    };

    const applyInterceptor = (axiosClient, customOptions = {}) => {
        let isRefreshing = false;
        let failedQueue = [];

        const options = {
            attachTokenToRequest,
            handleTokenRefresh,
            setTokenData,
            shouldIntercept,
            ...customOptions,
        };
        const processQueue = (error, token = null) => {
            failedQueue.forEach((prom) => {
                if (error) {
                    prom.reject(error);
                } else {
                    prom.resolve(token);
                }
            });

            failedQueue = [];
        };

        const interceptor = (error) => {
            if (!options.shouldIntercept(error)) {
                return Promise.reject(error);
            }

            if (error.config._retry || error.config._queued) {
                return Promise.reject(error);
            }

            const originalRequest = error.config;
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject });
                })
                    .then((token) => {
                        originalRequest._queued = true;
                        options.attachTokenToRequest(originalRequest, token);
                        return axiosClient.request(originalRequest);
                    })
                    .catch((err) => {
                        return Promise.reject(error); // Ignore refresh token request's "err" and return actual "error" for the original request
                    });
            }

            originalRequest._retry = true;
            isRefreshing = true;
            return new Promise((resolve, reject) => {
                options.handleTokenRefresh
                    .call(options.handleTokenRefresh)
                    .then((tokenData) => {
                        const accessToken = tokenData.access_token;
                        options.setTokenData(tokenData, axiosClient);
                        options.attachTokenToRequest(
                            originalRequest,
                            accessToken
                        );
                        processQueue(null, accessToken);
                        resolve(axiosClient.request(originalRequest));
                    })
                    .catch((err) => {
                        processQueue(err, null);
                        reject(err);
                    })
                    .finally(() => {
                        isRefreshing = false;
                    });
            });
        };

        axiosClient.interceptors.response.use(undefined, interceptor);
    };

    return { applyInterceptor };
};

export default Interceptor;
