import { useSearchParams } from "react-router-dom";

const JobStatus = () => {
    const [queryString] = useSearchParams();
    return (
        <div id="auth">
            <div className="row h-100 mt-5">
                <div className="offset-lg-3 col-lg-6 col-12">
                    <div id="auth-forgot-password">
                        <h1 className="auth-title">Job Response</h1>
                        <p className="auth-subtitle mb-5">
                            {queryString.get("status")}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobStatus;
