import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import applyAxioxInterceptor from "./redux/interceptor";
import _axios from "./redux/api";
import { ProSidebarProvider } from "react-pro-sidebar";
import "../src/css/style.scss";
import "bootstrap/dist/js/bootstrap.js";

import { store } from "./redux/store";

applyAxioxInterceptor(store).applyInterceptor(_axios);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <ProSidebarProvider>
                    <App />
                </ProSidebarProvider>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
);
