import _axios from "../api";
import { AuthHeader } from "./auth.header";

export const register = async (registerObj) =>
    await _axios.post("/register", JSON.stringify(registerObj)).then((response) => {
        return response.data;
    });

export const login = async (dataObj) =>
    await _axios.post(`/login`, JSON.stringify(dataObj)).then((response) => {
        if (response.data.data.access_token && response.data.data.refresh_token) {
            localStorage.setItem("user", JSON.stringify(response.data.data));
        }

        return response.data;
    });

export const forgotPassword = async (forgotPasswordObj) =>
    await _axios.post(`/forgot-password`, JSON.stringify(forgotPasswordObj)).then((response) => {
        return response.data;
    });

export const changePassword = async (currentPassword, newPassword, confirmNewPassword) =>
    await _axios
        .post(
            `/change-password`,
            JSON.stringify({
                currentPassword,
                newPassword,
                confirmNewPassword,
            }),
            {
                headers: AuthHeader(),
            }
        )
        .then((response) => {
            return response.data;
        });

export const passwordReset = async (resetPasswordObj) =>
    await _axios.post(`/password-reset`, JSON.stringify(resetPasswordObj)).then((response) => {
        return response.data;
    });

export const logout = () => localStorage.removeItem("user");

export const getProfile = async () =>
    await _axios
        .get(`/profile`, {
            headers: AuthHeader(),
        })
        .then((response) => {
            return response.data;
        });

export const saveProfile = async (profileObj) =>
    await _axios
        .post(`/profile`, JSON.stringify(profileObj), {
            headers: AuthHeader(),
        })
        .then((response) => {
            return response.data;
        });
