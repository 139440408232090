import { Outlet } from "react-router-dom";
import Header from "./header";

export default function FrontLayout() {
    return (
        <div className="layout-wrapper layout-content-navbar front">
            <div id="main" className="layout-navbar">
                <Header />
                <div id="main-content">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}
