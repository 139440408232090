import { useSelector, useDispatch } from "react-redux";
//import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { logout } from "../../redux/actions/auth";

export default function Header() {
    const dispatch = useDispatch();

    const { isLoggedIn } = useSelector((state) => state.auth);

    const handleLogout = () => {
        //console.log("logout called");
        return dispatch(logout());
    };

    return (
        <header className="mb-3">
            <nav className="navbar navbar-expand navbar-light navbar-top">
                <div
                    className="container-fluid  bg-white ms-2 me-2 p-3"
                    style={{ borderRadius: "5px" }}
                >
                    <div className="collapse navbar-collapse">
                        <a href="/"><h4 ><b>Post A Job</b></h4></a>
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item me-2">
                                <Link to="/" className="btn btn-primary">
                                    <i className="bi bi-plus-lg"></i>
                                    &nbsp; New Job
                                </Link>
                            </li>
                            {isLoggedIn && (
                                <li className="nav-item me-2">
                                    <Link
                                        to="/my-posted-job"
                                        className="btn btn-primary"
                                    >
                                        <i className="bi bi-briefcase"></i>
                                        &nbsp; My Jobs
                                        {/* <Badge bg="danger">1</Badge> */}
                                    </Link>
                                </li>
                            )}
                            {!isLoggedIn && (
                                <li className="nav-item">
                                    <Link
                                        to="/login"
                                        className="btn btn-primary"
                                    >
                                        <i className="bi bi-box-arrow-in-right"></i>
                                        &nbsp; Login
                                    </Link>
                                </li>
                            )}
                            {isLoggedIn && (
                                <>
                                    <li className="nav-item">
                                        <div className="dropdown">
                                            <button
                                                className="btn btn-primary dropdown-toggle"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                <i className="bi bi-person-bounding-box"></i>
                                                &nbsp; Account
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end">
                                                <li>
                                                    <Link
                                                        to="/profile"
                                                        className="dropdown-item"
                                                    >
                                                        <i className="bi bi-person-lines-fill"></i>
                                                        &nbsp; Profile
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to="/change-password"
                                                        className="dropdown-item"
                                                    >
                                                        <i className="bi bi-key"></i>
                                                        &nbsp; Change Password
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        className="dropdown-item"
                                                        href="#"
                                                        onClick={handleLogout}
                                                    >
                                                        <i className="bi bi-box-arrow-right"></i>
                                                        &nbsp; Logout
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}
