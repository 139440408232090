import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
const PrivateRoute = ({ children, role }) => {
    const { isLoggedIn, user } = useSelector((state) => state.auth);
    if (!isLoggedIn) return <Navigate to={{ pathname: "/login" }} />;

    var token = jwtDecode(user.access_token);
    if (role && role !== token.user_type)
        return <Navigate to={{ pathname: "/unauthorized" }} />;

    return children;
};
export default PrivateRoute;
