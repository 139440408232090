import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import AdminLayout from "../admin/layout";
import FrontLayout from "./frontLayout";

const LayoutDecider = () => {
    const { user } = useSelector((state) => state.auth);
    var token = {};
    if (user?.access_token) {
        token = jwtDecode(user.access_token);
    }
    return token.user_type === "ADMIN" ? <AdminLayout /> : <FrontLayout />;
};

export default LayoutDecider;
