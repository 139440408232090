import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
} from "./types";

import * as ServiceAPI from "../services/auth.service";

export const register = (registerObj) => (dispatch) => {
    return ServiceAPI.register(registerObj).then(
        (response) => {
            dispatch({
                type: REGISTER_SUCCESS,
            });
            return Promise.resolve(response.data);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: REGISTER_FAIL,
            });
            return Promise.reject(message);
        }
    );
};

export const login = (email, password) => (dispatch) => {
    return ServiceAPI.login(email, password).then(
        (res) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: { user: res.data },
            });

            return Promise.resolve(res.data);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: LOGIN_FAIL,
            });

            return Promise.reject(message);
        }
    );
};

export const forgotPassword = (email) => {
    return ServiceAPI.forgotPassword(email).then(
        (data) => {
            return Promise.resolve(data.message);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            return Promise.reject(message);
        }
    );
};

export const changePassword = (
    currentPassword,
    newPassword,
    confirmNewPassword
) => {
    return ServiceAPI.changePassword(
        currentPassword,
        newPassword,
        confirmNewPassword
    ).then(
        (data) => {
            return Promise.resolve(data.message);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            return Promise.reject(message);
        }
    );
};

export const passwordReset = (lid, code, password, confirmPassword) => {
    return ServiceAPI.passwordReset(lid, code, password, confirmPassword).then(
        (data) => {
            return Promise.resolve(data.message);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            return Promise.reject(message);
        }
    );
};

export const logout = () => (dispatch) => {
    ServiceAPI.logout();
    dispatch({
        type: LOGOUT,
    });
};
export const getProfile = () => {
    return ServiceAPI.getProfile().then(
        (data) => {
            return Promise.resolve(data);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            return Promise.reject(message);
        }
    );
};

export const saveProfile = (profileObj) => {
    return ServiceAPI.saveProfile(profileObj).then(
        (data) => {
            return Promise.resolve(data.message);
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            return Promise.reject(message);
        }
    );
};
