const Loading = () => {
    return (
        <div className="d-flex justify-content-center">
            <div
                className="spinner-border"
                role="status"
                style={{ width: "5rem", height: "5rem" }}
            ></div>
        </div>
    );
};
export default Loading;
