import { Link } from "react-router-dom";

export default function Unauthorized() {
    return (
        <div id="error">
            <div className="error-page container">
                <div className="col-md-8 col-12 offset-md-2">
                    <div className="text-center">
                        <img
                            className="img-error"
                            src="/images/error-403.svg"
                            alt="Not Found"
                        />
                        <h1 className="error-title">Forbidden</h1>
                        <p className="fs-5 text-gray-600">
                            You are unauthorized to see this page.
                        </p>
                        <Link
                            to="/"
                            className="btn btn-lg btn-outline-primary mt-3"
                        >
                            Go Home
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
