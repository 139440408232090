import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import NoMatch from "./404";
import Unauthorized from "./403";
import PrivateRoute from "./privateRoute";
import Loading from "./_loading";
import JobStatus from "../jobStatus";
import LayoutDecider from "./layoutDecider";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const PostAJob = React.lazy(() => import("../postAJob"));
const PostedJobEdit = React.lazy(() => import("../PostedJobEdit"));
const PostedJobDetail = React.lazy(() => import("../PostedJobDetail"));
const PostedJobs = React.lazy(() => import("../postedJobs"));
const Login = React.lazy(() => import("../login"));
const Register = React.lazy(() => import("../register"));
const ForgotPassword = React.lazy(() => import("../forgotPassword"));
const Profile = React.lazy(() => import("../profile"));
const ChangePassword = React.lazy(() => import("../changePassword"));
const PasswordReset = React.lazy(() => import("../passwordReset"));
const Dashboard = React.lazy(() => import("../admin/dashboard"));
const Jobs = React.lazy(() => import("../admin/jobs"));
const LeadGeneration = React.lazy(() => import("../admin/lead-generation"));
const LeadDetails = React.lazy(() => import("../admin/lead-details"));
const TermsAndConditions = React.lazy(() => import("../termsAndConditions"));
const UserList = React.lazy(() => import("../admin/userlist"));
const User = React.lazy(() => import("../admin/user"));
const SeriLogs = React.lazy(() => import("../admin/seriLog"));
const EmailLog = React.lazy(() => import("../admin/emailLog"));

const Router = function () {
    return (
        <Routes>
            <Route path="/job-status" element={<JobStatus />}></Route>
            <Route path="/" element={<LayoutDecider />}>
                <Route
                    index
                    path="/"
                    element={
                        <Suspense fallback={<Loading />}>
                            <PostAJob />
                        </Suspense>
                    }
                />

                <Route
                    index
                    path="/edit-job/:id"
                    element={
                        <Suspense fallback={<Loading />}>
                            <PrivateRoute role="CLIENT">
                                <PostedJobEdit />
                            </PrivateRoute>
                        </Suspense>
                    }
                />

                <Route
                    index
                    path="/job-detail/:id"
                    element={
                        <Suspense fallback={<Loading />}>
                            <PrivateRoute role="CLIENT">
                                <PostedJobDetail />
                            </PrivateRoute>
                        </Suspense>
                    }
                />

                <Route
                    path="/my-posted-job"
                    element={
                        <Suspense fallback={<Loading />}>
                            <PrivateRoute role="CLIENT">
                                <PostedJobs />
                            </PrivateRoute>
                        </Suspense>
                    }
                />
                <Route
                    path="/login"
                    element={
                        <Suspense fallback={<Loading />}>
                            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLERECAPCHA_KEY} scriptProps={{ defer: true, async: true }}>
                                <Login />
                            </GoogleReCaptchaProvider>
                        </Suspense>
                    }
                />
                <Route
                    path="/forgot-password"
                    element={
                        <Suspense fallback={<Loading />}>
                            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLERECAPCHA_KEY} scriptProps={{ defer: true, async: true }}>
                                <ForgotPassword />
                            </GoogleReCaptchaProvider>
                        </Suspense>
                    }
                />
                <Route
                    path="/password-reset"
                    element={
                        <Suspense fallback={<Loading />}>
                            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLERECAPCHA_KEY} scriptProps={{ defer: true, async: true }}>
                                <PasswordReset />
                            </GoogleReCaptchaProvider>
                        </Suspense>
                    }
                />
                <Route
                    path="/register"
                    element={
                        <Suspense fallback={<Loading />}>
                            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLERECAPCHA_KEY} scriptProps={{ defer: true, async: true }}>
                                <Register />
                            </GoogleReCaptchaProvider>
                        </Suspense>
                    }
                />

                <Route
                    path="/profile"
                    element={
                        <Suspense fallback={<Loading />}>
                            <PrivateRoute>
                                <Profile />
                            </PrivateRoute>
                        </Suspense>
                    }
                />

                <Route
                    path="/change-password"
                    element={
                        <Suspense fallback={<Loading />}>
                            <PrivateRoute>
                                <ChangePassword />
                            </PrivateRoute>
                        </Suspense>
                    }
                />

                <Route
                    path="/terms-and-conditions"
                    element={
                        <Suspense fallback={<Loading />}>
                            <TermsAndConditions />
                        </Suspense>
                    }
                />
                <Route
                    index
                    path="/admin"
                    element={
                        <Suspense fallback={<Loading />}>
                            <PrivateRoute role="ADMIN">
                                <Dashboard />
                            </PrivateRoute>
                        </Suspense>
                    }
                />
                <Route
                    index
                    path="/admin/jobs"
                    element={
                        <Suspense fallback={<Loading />}>
                            <PrivateRoute role="ADMIN">
                                <Jobs />
                            </PrivateRoute>
                        </Suspense>
                    }
                />
                <Route
                    index
                    path="/admin/lead-generation"
                    element={
                        <Suspense fallback={<Loading />}>
                            <PrivateRoute role="ADMIN">
                                <LeadGeneration />
                            </PrivateRoute>
                        </Suspense>
                    }
                />
                <Route
                    index
                    path="/admin/lead-details/:organisationid"
                    element={
                        <Suspense fallback={<Loading />}>
                            <PrivateRoute role="ADMIN">
                                <LeadDetails />
                            </PrivateRoute>
                        </Suspense>
                    }
                />
                <Route
                    index
                    path="/admin/user/:clienttype"
                    element={
                        <Suspense fallback={<Loading />}>
                            <PrivateRoute role="ADMIN">
                                <UserList />
                            </PrivateRoute>
                        </Suspense>
                    }
                />
                <Route
                    index
                    path="/admin/user/:clienttype/:type/:id?"
                    element={
                        <Suspense fallback={<Loading />}>
                            <PrivateRoute role="ADMIN">
                                <User />
                            </PrivateRoute>
                        </Suspense>
                    }
                />
                <Route
                    index
                    path="/admin/serilog/list"
                    element={
                        <Suspense fallback={<Loading />}>
                            <PrivateRoute role="ADMIN">
                                <SeriLogs />
                            </PrivateRoute>
                        </Suspense>
                    }
                />
                <Route
                    index
                    path="/admin/emaillog/list"
                    element={
                        <Suspense fallback={<Loading />}>
                            <PrivateRoute role="ADMIN">
                                <EmailLog />
                            </PrivateRoute>
                        </Suspense>
                    }
                />
                <Route path="*" element={<NoMatch />} />
            </Route>
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="*" element={<NoMatch />} />
        </Routes>
    );
};
export default Router;
